import React from "react"
import AboutLanding from "../components/pages/about/nav/AboutLanding"
function About({ location }) {
  return (
    <div class="">
      <AboutLanding location={location}> i am the about page</AboutLanding>
    </div>
  )
}

export default About