import React from 'react'
import '../about.scss'

import AboutSliders from '../concept/AboutSliders'


function Intro() {
  

    return (
        <div class="">
<AboutSliders contentRight={true} startNum={0} page="intro"/>
        </div>
    )
}

export default Intro
